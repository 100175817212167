let config = window.app.config

if (window.app.config.NODE_ENV === 'development') {
    config = process.env
}

export const BRAND = config.VUE_APP_BRAND
export const SOCKET_ENDPOINT = config.VUE_APP_SOCKET_ENDPOINT
export const REST_ENDPOINT = config.VUE_APP_REST_ENDPOINT
export const WEBSITE = config.VUE_APP_WEBSITE || 'https://' + window.location.host.split('.').slice(1).join('.')
export const CDN_ENABLED = config.VUE_APP_CDN

export const STATIC_BASE = config.VUE_APP_STATIC_BASE
export const COMPANY_NAME = config.VUE_APP_COMPANY_NAME
export const SUPPORT_EMAIL = config.VUE_APP_SUPPORT_EMAIL
export const SYMBOLS_ACTIVE = config.VUE_APP_SYMBOLS_ACTIVE ? JSON.parse(config.VUE_APP_SYMBOLS_ACTIVE) : ''
export const LOGO = JSON.parse(config.VUE_APP_LOGO)
export const FAVICON = config.VUE_APP_FAVICON
export const LANGUAGES = JSON.parse(config.VUE_APP_LANGUAGES)
export const VARIABLES = JSON.parse(config.VUE_APP_VARIABLES).length > 0 ? JSON.parse(config.VUE_APP_VARIABLES) : ['BT_ACCOUNT', 'BT_BANK_NAME', 'BT_BENEFICIARY', 'BT_ADDRESS', 'BT_SWIFT', 'BT_IBAN', 'PENDING_DEPOSITS_LIMIT']
export const LANG_DEFAULT = config.VUE_APP_LANG_DEFAULT
export const DISABLE_REGISTRATION = config.VUE_APP_DISABLE_REGISTRATION || 0
export const DROP_SESSION = config.VUE_APP_DROP_SESSION || 0
export const MINIMUM_DEPOSIT = config.VUE_APP_MINIMUM_DEPOSIT || 250
export const MAXIMUM_DEPOSIT = config.VUE_APP_MAXIMUM_DEPOSIT || 1000000
export const MINIMUM_WITHDRAWAL = config.VUE_APP_MINIMUM_WITHDRAWAL || 0
export const CHART_FULL_TIMEFRAMES = ['1', '5', '15', '30', '60', '240', '1440', '1W', '1M']
export const VOLUME_TYPES = config.VUE_APP_VOLUME_TYPES ? JSON.parse(config.VUE_APP_VOLUME_TYPES) : ['lots','units','currency']
export const MULTIGRID = config.VUE_APP_MULTIGRID
export const KYC_DOCUMENTS = JSON.parse(config.VUE_APP_KYC_DOCUMENTS)
export const SHOW_EQUITY_HEADER = config.VUE_APP_SHOW_EQUITY_HEADER || 0
export const NOT_WORKING_HOURS = config.VUE_APP_NOT_WORKING_HOURS ? JSON.parse(config.VUE_APP_NOT_WORKING_HOURS) : 0

export const PSP_CONFIG = {
    realeasypay: {
        name: 'Credit/Debit Card / REP',
        currencies: ['USD']
    },
    cascading: {
        name: 'Credit/Debit Card / CASC',
        currencies: ['USD', 'RUB']
    },
    finanic: {
        name: 'Credit/Debit Card / FIN',
        currencies: ['USD', 'EUR']
    },
    betatransfer: {
        name: 'Credit/Debit Card / BT',
        currencies: ['RUB', 'USD']
    },
    prmoney: {
        name: 'Credit/Debit Card / PRM',
        currencies: ['USD']
    },
    starbill_usd: {
        name: 'Credit/Debit Card / SBU',
        currencies: ['USD']
    },
    starbill_eur: {
        name: 'Credit/Debit Card / SBE',
        currencies: ['EUR']
    },
    paypound: {
        name: 'Credit/Debit Card / PP',
        currencies: ['USD', 'EUR', 'GBP']
    },
    kryptova: {
        name: 'Credit/Debit Card / KR',
        currencies: ['USD', 'EUR', 'GBP']
    },
    walletix: {
        name: 'Credit/Debit Card / WI',
        currencies: ['USD', 'EUR', 'RUB', 'PLN']
    },
    walletcomru: {
        name: 'Credit/Debit Card / WCR',
        currencies: ['USD']
    },
    payzon: {
        name: 'Credit/Debit Card / PZ',
        currencies: ['USD', 'EUR']
    },
    bitexbit: {
        name: 'Credit/Debit Card / BB',
        currencies: ['UAH']
    },
    paystudio: {
        name: 'Credit/Debit Card / PS',
        currencies: ['USD', 'GBP', 'EUR', 'AUD', 'NZD']
    },
    stripe: {
        name: 'Credit/Debit Card / St',
        currencies: ['CAD','USD', 'EUR']
    },
    settlepay_usd: {
        name: 'Credit/Debit Card / SPU',
        currencies: ['USD']
    },
    settlepay_eur: {
        name: 'Credit/Debit Card / SPE',
        currencies: ['EUR']
    },
    garrypay: {
        name: 'Credit/Debit Card / GP',
        currencies: ['USD', 'EUR']
    },
    texcent: {
        name: 'Credit/Debit Card / TC',
        currencies: ['USD']
    },
    billing_ru: {
        name: 'Billing-Ru',
        currencies: ['RUB', 'USD', 'EUR', 'UAH']
    },
    billing_world: {
        name: 'Billing-World',
        currencies: ['RUB', 'USD', 'EUR', 'UAH']
    },
    honeypay: {
        name: 'Credit/Debit Card / HP',
        currencies: ['USD']
    },
    square: {
        name: 'Credit/Debit Card / SQ',
        currencies: ['USD']
    },
    wellex: {
        name: 'Credit/Debit Card / WX',
        currencies: ['RUB','UZS']
    },
    villpay: {
        name: 'Credit/Debit Card / VP',
        currencies: ['RUB']
    },
    pinikle: {
        name: 'Credit/Debit Card / Pinikle',
        currencies: ['USD']
    },
    myfatoorah: {
        name: 'Credit/Debit Card / Myfatoorah',
        currencies: ['USD']
    },
    solidpayment: {
        name: 'Credit/Debit Card / Solid Payments',
        currencies: ['USD','EUR','GBP'],
    },
    payepo: {
        name: 'Credit/Debit Card / Payepo',
        currencies: ['USD']
    },
    wpay: {
        name: 'UPI PAY',
        currencies: ['INR']
    },
    wpay_upi: {
        name: 'UPI 1',
        currencies: ['INR']
    },
    nonstopay: {
        name: 'UPI Click',
        currencies: ['USD']
    },
    gatetwoway: {
        name: 'UPI GW',
        currencies: ['INR']
    },
    gatetwoway_imps : {
        name: 'IMPS  GateWay',
        currencies: ['INR']
    },
    deluxepay: {
        name: 'UPI/Netbanking',
        currencies: ['INR']
    },
    cricpayz: {
        name: 'UPI Go',
        currencies: ['INR']
    },
    cricpayz_bank: {
        name: 'Quantum Go',
        currencies: ['INR']
    },
    centiwise: {
        name: 'Centiwise',
        currencies: ['INR']
    },
    quikipay: {
        name: 'quikipay',
        currencies: ['USD']
    },
    zyaadapay: {
        name: 'Zyaadapay',
        currencies: ['INR']
    },
    ttpay: {
        name: 'TTPay',
        currencies: ['PHP']
    },
}

export const PSP_CONFIG_COUNTRIES = {
    payretailers: {
        name: 'Credit/Debit Card / Payretailers',
        countries: {
            AR: {
                name: 'Argentina',
                currencies: ['USD']
            },
            BR: {
                name: 'Brazil',
                currencies: ['USD']
            },
            CL: {
                name: 'Chile',
                currencies: ['USD']
            },
            CO: {
                name: 'Colombia',
                currencies: ['USD']
            },
            CR: {
                name: 'Costa Rica',
                currencies: ['USD']
            },
            GT: {
                name: 'Guatemala',
                currencies: ['USD']
            },
            MX: {
                name: 'Mexico',
                currencies: ['USD']
            },
            PE: {
                name: 'Peru',
                currencies: ['USD']
            },
            EC: {
                name: 'Ecuador',
                currencies: ['USD']
            },
            PA: {
                name: 'Panama',
                currencies: ['USD']
            }
        }
    },
    '4on': {
        name: 'Credit/Debit Card / 4on',
        countries: {
            AR: {
                name: 'Argentina',
                currencies: ['USD'],
                methods: {
                    cards: 'Cards',
                    pagofacil: 'PagoFacil',
                    rapipago: 'RapiPago'
                },
            },
            MX: {
                name: 'Mexico',
                currencies: ['USD'],
                methods: {
                    bankTransfer: 'Bank Transfer',
                    card: 'Cards',
                    oxxo: 'OXXO'
                },
            },
            CO: {
                name: 'Colombia',
                currencies: ['USD'],
                methods: {
                    card: 'Cards',
                    efecty: 'Efecty',
                    pse: 'PSE'
                }
            },
            CL: {
                name: 'Chile',
                currencies: ['USD'],
                methods: {
                    card: 'Cards',
                    webpay: 'WebPay'
                }
            },
            PE: {
                name: 'Peru',
                currencies: ['USD'],
                methods: {
                    card: 'Cards',
                    pagoefectivo_atm: 'Pago Efectivo'
                },
            },
            BR: {
                name: 'Brazil',
                currencies: ['USD'],
                methods:{
                    bolbradesco: 'Boleto',
                    card: 'Cards',
                    pec: 'PEC',
                    pix: 'PIX'
                },
            },
        }
    }
}

export const DEPOSIT_BUTTONS = {
    USD: [10, 50, 100, 250, 500, 1000, 2000, 3000, 5000, 10000],
    EUR: [10, 50, 100, 250, 500, 1000, 2000, 3000, 5000, 10000],
    GBP: [10, 50, 100, 250, 500, 1000, 2000, 3000, 5000, 10000],
    RUB: [1500, 5000, 10000, 19000, 25000, 30000, 45000, 60000]
}

export const COLOR_BG_FOOTER          = config.VUE_APP_COLOR_BG_FOOTER          || '#18202d' // footer
export const COLOR_BG_DARK            = config.VUE_APP_COLOR_BG_DARK            || '#181f2d' // MAIN BACKGROUND EVERYWHERE
export const COLOR_BG_DARK_MID        = config.VUE_APP_COLOR_BG_DARK_MID        || '#252c3b' // account modal, left part bg (+ most pads in dashboard)
export const COLOR_BG                 = config.VUE_APP_COLOR_BG                 || '#374054' // text input (+toast)
export const COLOR_BG_LIGHT           = config.VUE_APP_COLOR_BG_LIGHT           || '#363f52' // bg of left menu and ordermenu inputs
export const COLOR_WHITE              = config.VUE_APP_COLOR_WHITE              || '#ffffff'
export const COLOR_FONT               = config.VUE_APP_COLOR_FONT               || '#8c8f96'
export const COLOR_BRAND              = config.VUE_APP_COLOR_BRAND              || '#1d98f9'
export const COLOR_GREEN              = config.VUE_APP_COLOR_GREEN              || '#2bab40'
export const COLOR_GREEN_BUTTON       = config.VUE_APP_COLOR_GREEN_BUTTON       || '#2bab40'
export const COLOR_RED                = config.VUE_APP_COLOR_RED                || '#f15350'
export const COLOR_PRIMARY_BUTTON     = config.VUE_APP_COLOR_PRIMARY_BUTTON     || '#1d98f9'
export const COLOR_SECONDARY_BUTTON   = config.VUE_APP_COLOR_SECONDARY_BUTTON   || '#424a5e'
export const COLOR_BORDER             = config.VUE_APP_COLOR_BORDER             || '#353c4e'
export const TRADINGVIEW_BG           = config.VUE_APP_TRADINGVIEW_BG           || '#181f2d'
export const TRADINGVIEW_THEME        = config.VUE_APP_TRADINGVIEW_THEME        || 'Dark'
export const TRADINGVIEW_LINE         = config.VUE_APP_TRADINGVIEW_LINE         || 'rgba(255,255,255,.1)'
export const COLOR_MARKET_WATCH_TITLE = config.VUE_APP_COLOR_MARKET_WATCH_TITLE || '#374054' // bg by default
export const COLOR_BG_ASSETS_INFO = config.VUE_APP_COLOR_BG_ASSETS_INFO || 'linear-gradient(0deg, rgba(24, 31, 45, 1) 0%, rgba(24, 31, 45, 1) 45%, rgba(24, 31, 45, 0.45) 100%)'

export const NEW_DESIGN = config.VUE_APP_NEW_DESIGN || false
export const PERSONAL_BTNS = config.VUE_APP_PERSONAL_BTNS || []
export const CLOSE_WITHDRAWAL = config.VUE_APP_CLOSE_WITHDRAWAL || 0

export const ROLE = config.VUE_APP_ROLE || 0
export const HIDE_FORGOT_PASSWORD = config.VUE_APP_HIDE_FORGOT_PASSWORD || 0

export const SHOW_LEVERAGE = config.VUE_APP_SHOW_LEVERAGE || 1
export const SCHEDULE = config.VUE_APP_SUPPORT_SCHEDULE || ''

export const PLATES_FOLDER = config.VUE_APP_PLATES_FOLDER || 'plates'

export const MARKET_WATCH_DEFAULT_CAT = config.VUE_APP_MARKET_WATCH_DEFAULT_CAT || 'forex'

export const NEWS_CATEGORIES = config.VUE_APP_NEWS_CATEGORIES ? JSON.parse(config.VUE_APP_NEWS_CATEGORIES) : 'all' // env is an array: ['crypto', 'nft', ...], only crypto for now

export const WITHDRAWAL_OPTIONS = config.VUE_APP_WITHDRAWAL_OPTIONS ? JSON.parse(config.VUE_APP_WITHDRAWAL_OPTIONS) : {"bank": "bank", "btc": "bitcoin", "card": "card", "eth": "ethereum", "usdt": "usdt", "matic": "matic"}

export const BANK_WITHDRAWAL_FIELDS = config.VUE_APP_BANK_WITHDRAWAL_FIELDS ? typeof config.VUE_APP_BANK_WITHDRAWAL_FIELDS  === 'string' ? JSON.parse(config.VUE_APP_BANK_WITHDRAWAL_FIELDS) : config.VUE_APP_BANK_WITHDRAWAL_FIELDS : ['account','holder','iban','swift'] // if env from cpanel it is a array, if local env it is a string

export const INITIAL_DASHBOARD = config.VUE_APP_INITIAL_DASHBOARD || 0
export const SHOW_REGISTER_MODAL = config.VUE_APP_SHOW_REGISTER_MODAL || 0
export const CUSTOM_SHOWING_STAKING_ACCOUNT = config.VUE_APP_CUSTOM_SHOWING_STAKING_ACCOUNT
export const MODULE_FTD = config.VUE_APP_MODULE_FTD || ''
export const CUSTOM_PSP_NAME = config.VUE_APP_CUSTOM_PSP_NAME ? JSON.parse(config.VUE_APP_CUSTOM_PSP_NAME) : ''
export const DISABLE_ACTIVITY_LOG = config.VUE_APP_DISABLE_ACTIVITY_LOG || false
export const DISABLE_INFO_TABLE = config.VUE_APP_DISABLE_INFO_TABLE || 0
export const NEW_VERIFICATION_TITLE = config.VUE_APP_NEW_VERIFICATION_TITLE
export const DEFAULT_WITHDRAWAL_CURRENCY = config.VUE_APP_DEFAULT_WITHDRAWAL_CURRENCY
export const SHOW_TAXES = config.VUE_APP_SHOW_TAXES || ''
export const HIDE_DEPOSIT_TABLE = config.VUE_APP_HIDE_DEPOSIT_TABLE || ''
export const OTHER_PSP_TAB_NAME = config.VUE_APP_OTHER_PSP_TAB_NAME || ''
export const HIDE_VERIFICATION_TAB = config.VUE_APP_HIDE_VERIFICATION_TAB || ''
export const HIDE_DEPOSIT_OPTION = config.VUE_APP_HIDE_DEPOSIT_OPTION || ''
export const HIDE_TRADING_RESULT = config.VUE_APP_MODULE_FTD_RESULT || ''
export const DISABLE_TRADING = config.VUE_APP_DISABLE_TRADING || ''
export const CUSTOM_SELFIE = config.VUE_APP_CUSTOM_SELFIE || ''
export const CUSTOM_SELFIE_TEXT = config.VUE_APP_CUSTOM_SELFIE_TEXT || ''
export const SHOW_TERMS = config.VUE_APP_SHOW_TERMS || ''
export const HIDE_LOGO = config.VUE_APP_HIDE_LOGO
export const REQUIRED_WITHDRAWAL = config.VUE_APP_REQUIRED_WITHDRAWAL || ''
export const HIDE_WITHDRAWAL_TIME = config.VUE_APP_HIDE_WITHDRAWAL_TIME || ''
export const HIDE_DEPOSIT_TIME = config.VUE_APP_HIDE_DEPOSIT_TIME || ''
export const HIDE_DASHBOARD_TIME = config.VUE_APP_HIDE_DASHBOARD_TIME || ''
export const CUSTOM_KYS_DOCUMENT = config.VUE_APP_CUSTOM_KYS_DOCUMENT || ''
export const DEPOSIT_ADDITIONAL_INFO = config.VUE_APP_DEPOSIT_ADDITIONAL_INFO || ''
export const SELF_WITHDRAWAL_CANCEL_TEXT = config.VUE_APP_SELF_WITHDRAWAL_CANCEL_TEXT || ''
export const WHITE_THEME = config.VUE_APP_WHITE_THEME || ''
export const HIDE_2FA = config.VUE_APP_HIDE_2FA || ''
export const UPI_GO_QRCODE = config.VUE_APP_UPI_GO_QRCODE || ''
export const SOFORT_CUSTOM_PSP = config.VUE_APP_SOFORT_CUSTOM_PSP || ''
export const PROFIT_IN_PRECENT = config.VUE_APP_PROFIT_IN_PRECENT || ''
export const ONLY_BTC_PSP = config.VUE_APP_ONLY_BTC_PSP || ''
export const SHOW_TO_REGISTER_BUTTON = config.VUE_APP_SHOW_TO_REGISTER_BUTTON || ''
export const AUTO_LOGOUT = config.VUE_APP_AUTO_LOGOUT || false
export const CHANGE_PERSONAL_NAME = config.VUE_APP_CHANGE_PERSONAL_NAME || false
export const DEFAULT_WHITE_THEME = config.VUE_APP_DEFAULT_WHITE_THEME || false
export const FAKE_PSP_ADDITIONAL_FIELDS = config.VUE_APP_FAKE_PSP_ADDITIONAL_FIELDS || false
export const ONLY_WHITE_THEME = config.VUE_APP_ONLY_WHITE_THEME || false
export const SUPPORT_NOTIFICATION_WHIT_TEXT = config.VUE_APP_SUPPORT_NOTIFICATION_WHIT_TEXT || false
export const HIDE_TOTAL_DEPOSITS = config.VUE_APP_HIDE_TOTAL_DEPOSITS || false
export const HIDE_LIVE_CHAT = config.VUE_APP_HIDE_LIVE_CHAT || false
